import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconHeartFilled: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="heart-filled">
    <path d="M4.347 13c3.166 4 6.65 6.5 7.653 7 .992-.5 4.487-3 7.653-7 3.166-4 .275-9-3.653-9-1.964 0-3.067 1.111-4 2-.951-.889-2.036-2-4-2-3.928 0-6.819 5-3.653 9Z" />
  </Icon>
);
